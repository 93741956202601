<template>
    <div id="ItemContainer" class="margintop">
        <div id="TopbarContainer">
            <h1 id="Title" class="px-1">Documentos Oficiais</h1>
        </div>
        <div id="ItemDisplay">
            <div v-for="doc in docs" :key="doc.id">
                <div class="mt-4">
                    <h2 class="d-flex align-items-center" >
                        {{doc.name}}
                        <v-btn outlined icon @click="goTo(doc.url)" class="mx-4">
                            <v-icon v-text="icon"/>
                        </v-btn>
                    </h2>
                </div>
            </div>
            <v-btn v-if="displaySeeMore"
                id="SeeMore"
                plain
                :loading="loading"
                elevation="0"
                color="#888"
                width="300"
                @click="handleLoadMore"
                class="mt-3">
                Ver mais
            </v-btn>
        </div>
    </div>
</template>

<script>
import { mdiLink } from '@mdi/js';

export default {
    name: "Equipe",
    data: () => {
        return ({
            icon: mdiLink,
            page: 0,
            ammount: 10,
            seeMore: false,
            loading: false,
            docs: [],
            alldocs: [
                {
                    id: 0,
                    name: "Decreto nº 01, de 02 de janeiro de 2022",
                    url: `[APIURL]/uploads/atos/25228/MDIvMDEvMjAyMl8x.pdf`
                },
                {
                    id: 1,
                    name: "Decreto n° 81, de 10 de maio de 2022: LegisOn como Portal Oficial do Governo",
                    url: `[APIURL]/uploads/atos/25646/81.pdf`
                },
            ]
        })
    },
    methods: {
        getUrl: function () {
            this.alldocs.map(x => x.url = x.url.replace("[APIURL]", this.$apiURL));
        },
        getFirstResults: function () {
            let aux = [...this.alldocs];
            this.docs = aux.splice(0, this.ammount);
        },
        handleLoadMore: function () {
            this.loading = true;
            this.page++;
            let aux = [...this.alldocs];
            let more = aux.splice(this.page*this.ammount, this.ammount);
            this.docs = this.docs.concat(more);
            this.loading = false;
        },
        goTo: function (url) {
            window.open(url);
        }
    },
    mounted: function () {
        this.getUrl();
        this.getFirstResults();
    },
    computed: {
        displaySeeMore: function () {
            return (this.docs.length < this.alldocs.length)
        }
    }
}
</script>

<style>
@import "../About.css";
</style>